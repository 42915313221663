console.log("JS OK");



//-------------------------------------//
//  SMOOTH SCROLL ANCHOR
// not working in SAFARI
// https://caniuse.com/?search=scroll-behavior
//document.querySelectorAll('a[href^="#"]')

//Bouton menu (scroll bas pour éviter bug menu décalé)
document.querySelectorAll('.headerMobile a[href^="#"').forEach(function(anchor){
  anchor.addEventListener("click", function(e){
    e.preventDefault();
    // console.log('click menu');

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth',
      block: "end",
      inline: "nearest"
    });
  });
});

//Bouton UP
document.querySelectorAll('.triangleMobile').forEach(function(anchor){
  anchor.addEventListener("click", function(e){
    e.preventDefault();
    // console.log('click menu');

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth',
      block: "start",
      inline: "nearest"
    });
  });
});


// UNIQUEMENT SUR HOME ET ARCHIVE
var homepage = document.querySelector(".home");
var archivepage = document.querySelector(".archive");

if ( homepage || archivepage) {

    //-------------------------------------//
    // INIT INFINITE SCROLL
    var listeArticle = document.querySelector('.sectionProjets');

    var infScroll = new InfiniteScroll( listeArticle, {
      path: '.nextPageLink a',
      append: '.articleIndex',
      // outlayer: msnry,
      status: '.page-load-status',
      history: false,
      hideNav: '.nextPrev'
    });

} //FIN IF HOME ET ARCHIVE




//-------------------------------------//
// ANIMATION ACCUEIL
var animIntro = document.querySelector('#wrapperanim');
if (animIntro) {
  document.querySelector('html').classList.add('noScroll');


animIntro.addEventListener('animationend', function(){
      // document.querySelector('#wrapperanim').classList.add('hide');
      document.querySelector('html').classList.remove('noScroll');  
});


} //FIN IF HOME




//-------------------------------------//
// MASQUAGE DU MENU
var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("Dlogo").style.transform = "translate(0,0)";
  } else {
    document.getElementById("Dlogo").style.transform = "translate(0,18%)";
  }

  if (prevScrollpos > currentScrollPos) {
    document.getElementById("alogo").style.height = "auto";
  } else {
    document.getElementById("alogo").style.height = "40px";
  }
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("logo").style.transform = "translate(0,0%)";
  } else {
    document.getElementById("logo").style.transform = "translate(0,-70%)";
  }


          // effacer icone insta
          var icoInsta = document.querySelector(".headerDesktop .icoInstagram");
          if (icoInsta) {

            if (prevScrollpos > currentScrollPos) {
            // icoInsta.style.opacity = "1";
          } else {
            icoInsta.style.opacity = "0";
          }

          //remettre l'icone que tout en haut de la page
          if (currentScrollPos < 50) {
            icoInsta.style.opacity = "1";
          }

        }

          // mobile
          if (prevScrollpos > currentScrollPos) {
            document.getElementById("AlogoM").style.transform = "translate(0,0)";
            //remettre la hauteur au menu
            document.querySelector(".headerMobile").classList.add("hauteurMenu");
          } else {
            document.getElementById("AlogoM").style.transform = "translate(0,-70%)";
            //supprimer la hauteur du menu
            document.querySelector(".headerMobile").classList.remove("hauteurMenu");
          }
          if (prevScrollpos > currentScrollPos) {
            document.getElementById("BlogoM").style.transform = "translate(0,0)";
          } else {
            document.getElementById("BlogoM").style.transform = "translate(0,-70%)";
          }
          if (prevScrollpos > currentScrollPos) {
            document.getElementById("ClogoM").style.transform = "translate(0,0)";
          } else {
            document.getElementById("ClogoM").style.transform = "translate(0,-50%)";
          }
          if (prevScrollpos > currentScrollPos) {
            document.getElementById("DlogoM").style.transform = "translate(0,0)";
          } else {
            document.getElementById("DlogoM").style.transform = "translate(0,-70%)";
          }

          prevScrollpos = currentScrollPos;
        }
